import type { PaginationData } from 'egenie-utils';
import { EgGridModel, request, NormalProgramme, TimeStampFormatter } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import type { OwnerItem } from '../../types';

export class SelectOwnerModalStore {
  private extraParams: {[key: string]: any; } = {};

  private idField = 'id';

  private submitCallback?: (rows: OwnerItem[]) => any;

  @action private queryData = () => {
    this.egGridModel.loading = true;
    return request<PaginationData<OwnerItem>>({
      method: 'POST',
      url: '/api/cloud/baseinfo/rest/owner/queryOwnerReal',
      data: {
        enable: 1,
        page: this.egGridModel.current,
        pageSize: this.egGridModel.pageSize,
        ...this.normalProgramme.filterItems.params,
        ...this.extraParams,
      },
    })
      .then((info) => {
        this.egGridModel.rows = info?.data?.list;
        this.egGridModel.total = info?.data?.totalCount;
      })
      .finally(() => this.egGridModel.loading = false);
  };

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        field: 'ownerUniqueName',
        label: '货主名称',
        type: 'input',
      },
    ],
    handleSearch: () => this.queryData(),
  });

  @action public setExtraParams = (extraParams: SelectOwnerModalStore['extraParams']) => {
    this.extraParams = extraParams;
  };

  @observable public open = false;

  public egGridModel: EgGridModel = new EgGridModel({
    columns: [
      {
        key: 'ownerUniqueName',
        name: '货主名称',
        width: 200,
      },
      {
        key: 'ownerTypeName',
        name: '货主类型',
        width: 100,
      },
      {
        key: 'createdAt',
        name: '创建时间',
        width: 150,
        formatter: ({ row }) => <TimeStampFormatter value={row.createdAt}/>,
      },
    ].map((item) => ({
      resizable: true,
      sortable: false,
      ...item,
    })),
    rows: [],
    primaryKeyField: this.idField,
    sortByLocal: false,
    api: {
      onPageChange: () => {
        this.egGridModel.resetAll();
        this.queryData();
      },
      onShowSizeChange: () => {
        this.queryData();
      },
      onRefresh: () => {
        this.egGridModel.resetAll();
        this.queryData();
      },
    },
  });

  @action public handleClose = () => {
    this.open = false;
    this.submitCallback = null;
    this.egGridModel.clearToOriginal();
  };

  @action public handleOpen = (submitCallback?: SelectOwnerModalStore['submitCallback']) => {
    this.open = true;
    this.submitCallback = submitCallback;
  };

  @action public handleSubmit = () => {
    if (typeof this.submitCallback === 'function') {
      this.submitCallback(this.egGridModel.selectRows);
    }

    this.handleClose();
  };
}
