import type { PaginationData } from 'egenie-utils';
import { request, ImgFormatter } from 'egenie-utils';
import React from 'react';
import type { SubItem } from './types';
import { ENUM_ACTIVE_KEY } from './types';
import type { Store } from './store';

let id = 1;

export function detailGrid(context: Store) {
  return {
    tab: {
      name: '采购退货明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'productNo',
          name: '商品编码',
          sortable: true,
          width: 200,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          sortable: true,
          width: 200,
        },
        {
          key: 'barCode',
          name: '条形码',
          width: 200,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => {
            return <ImgFormatter value={row.pic}/>;
          },
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'number',
          name: '库存',
          width: 80,
        },
        {
          key: 'lockNumber',
          name: '锁定数量',
          width: 80,
        },
        {
          key: 'stockOut5Number',
          name: '5天出库量',
          width: 80,
        },
        {
          key: 'stockOut7Number',
          name: '7天出库量',
          width: 80,
        },
        {
          key: 'stockOut14Number',
          name: '14天出库量',
          width: 80,
        },
        {
          key: 'tagNames',
          name: '标签',
          width: 200,
        },
        {
          key: 'cityName',
          name: '地区',
          width: 200,
        },
        {
          key: 'marketName',
          name: '市场',
          width: 200,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: false,
      showPager: true,
    },
    api: {
      onQuery: ({
        data,
        cursorRow,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<SubItem[]>>({
          url: '/api/cloud/wms/rest/refund/detail/list/by/page',
          method: 'POST',
          data: {
            sidx,
            sord,
            page,
            pageSize,
            ...cursorRow,
            barCode: context.programme.filterItems.params.barCode,
            skuNo: context.programme.filterItems.params.skuNo,
            stockOutTimeRange: context.programme.filterItems.params.stockOutTimeRange,
            stockOutNumberRange: context.programme.filterItems.params.stockOutNumberRange,
            ownerIdStr: context.activeKey === ENUM_ACTIVE_KEY.vendor.value ? context.programme.filterItems.params.ownerIds : undefined,
          },
        })
          .then((info) => ({
            status: 'Successful',
            data: {
              ...info.data,
              list: (info.data?.list || []).map((item) => ({
                ...item,
                _id: id++,
              })),
            },
          }));
      },
    },
  };
}
