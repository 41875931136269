export interface MainItem {
  'warehouseName': string;
  'warehouseId': string;
  'vendorId': string;
  'vendorShopId': string;
  'vendorName': string;
  'ownerId': string;
  'ownerName': string;
  'warehouseAreaId': string;
  'warehouseAreaName': string;
  'number': number;
}

export interface SubItem {
  'ownerId': string;
  'number': string;
  'warehouseBinId': string;
  'warehouseBinName': string;
  'productNo': string;
  'skuId': string;
  'skuNo': string;
  'barCode': string;
  'pic': string;
  'color': string;
  'size': string;
}

export interface OwnerItem {
  id?: string;
  ownerId?: string;
  ownerType?: string;
  ownerName?: string;
  warehouseId?: string;
  warehouseName?: string;
  ownerCellphone?: string;
  ownerUniqueName?: string;
  enable?: boolean;
  enableTime?: string;
  sourceFrom?: number;
  usable?: boolean;
  nickName?: string;
  creatorName?: string;
  deliverStatus?: boolean;
  courierNames?: string;
}

export const ENUM_ACTIVE_KEY = {
  owner: {
    value: 'owner',
    label: '按货主查看',
  },
  vendor: {
    value: 'vendor',
    label: '按供应商查看',
  },
};
