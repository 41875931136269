import { observable, action } from 'mobx';
import React from 'react';
import { SelectOwnerModalStore } from './selectOwnerModal/selectOwnerModalStore';
import type { BaseData } from 'egenie-utils';
import { MainSubStructureModel, request } from 'egenie-utils';
import { Button, message } from 'antd';
import type { SettingOwnerItem, SettingData } from './types';
import { formatNumber } from 'egenie-common';

class SelectOwnerStore {
  public purchaseSelectOwnerModalStore: SelectOwnerModalStore = new SelectOwnerModalStore();

  @action private handleDeleteItem = (rows: SettingOwnerItem[]) => {
    const deleteIds = rows.map((item) => item.ownerId);
    this.mainSubStructureModel.gridModel.rows = this.mainSubStructureModel.gridModel.rows.filter((item) => !deleteIds.includes(item.ownerId));
  };

  @action public handleSelectOwner = () => {
    this.purchaseSelectOwnerModalStore.handleOpen((rows) => {
      rows.forEach((item) => {
        if (!this.mainSubStructureModel.gridModel.rows.find((val) => val.ownerId === item.ownerId)) {
          this.mainSubStructureModel.gridModel.rows.push({
            ...item,
            ownerName: item.ownerUniqueName,
          });
        }
      });
    });
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          frozen: true,
          formatter: ({ row }) => {
            return (
              <Button
                onClick={() => this.handleDeleteItem([row])}
                type="link"
              >
                删除
              </Button>
            );
          },
          width: 100,
        },
        {
          key: 'ownerName',
          name: '货主名称',
        },
        {
          key: 'ownerTypeName',
          name: '货主类型',
          width: 100,
          formatter: ({ row }) => (
            <span>
              {row.ownerTypeName || row.ownerName}
            </span>
          ),
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'ownerId',
      showSelectedTotal: false,
      showRefresh: false,
      showReset: false,
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: false,
      showPager: false,
    },
    hiddenSubTable: true,
    api: { onQuery: () => Promise.reject() },
  });
}

export class AutomaticRefundSettingModalStore {
  private handleResetState = () => {
    this.settingData = {};
    this.purchaseSelectOwnerStore.mainSubStructureModel.gridModel.clearToOriginal();
    this.orderSelectOwnerStore.mainSubStructureModel.gridModel.clearToOriginal();
    this.orderSelectOwnerStore.purchaseSelectOwnerModalStore.setExtraParams({ insteadOwnerAndMall: 1 });
  };

  public purchaseSelectOwnerStore = new SelectOwnerStore();

  public orderSelectOwnerStore = new SelectOwnerStore();

  @action private getSettingData = () => {
    request<BaseData<SettingData>>({ url: '/api/cloud/pms/refund/page/refund/policy' })
      .then((info) => {
        if (info.data) {
          this.settingData.wmsRefundPolicyId = info.data.wmsRefundPolicyId;
          this.settingData.lackOrderWmsRefundPolicyId = info.data.lackOrderWmsRefundPolicyId;
          this.settingData.saleDays = info.data.saleDays;
          this.settingData.minSales = info.data.minSales;
          this.settingData.refundPercent = info.data.refundPercent;
          this.settingData.enable = Boolean(info.data.enable);
          this.settingData.lackOrderEnable = Boolean(info.data.lackOrderEnable);
          this.purchaseSelectOwnerStore.mainSubStructureModel.gridModel.rows = info.data?.ownerPageVOS || [];
          this.orderSelectOwnerStore.mainSubStructureModel.gridModel.rows = info.data?.lackOrderOwnerPageVOS || [];
        }
      });
  };

  @observable public settingData: SettingData = {};

  @action public handleSettingEnableChange = (event): void => {
    this.settingData.enable = event.target.checked;
  };

  @action public handleSettingLackOrderEnableChange = (event): void => {
    this.settingData.lackOrderEnable = event.target.checked;
  };

  @action public handleSettingSaleDaysChange = (saleDays: number): void => {
    this.settingData.saleDays = formatNumber(saleDays);
  };

  @action public handleSettingMinSalesChange = (minSales: number): void => {
    this.settingData.minSales = formatNumber(minSales);
  };

  @observable public open = false;

  @action public handleOpen = () => {
    this.handleResetState();
    this.open = true;
    this.getSettingData();
  };

  @action public handleClose = () => {
    this.handleResetState();
    this.open = false;
  };

  @observable public isSubmit = false;

  @action public handleSubmit = async() => {
    if (this.settingData.enable && (formatNumber(this.settingData.saleDays) < 1 || formatNumber(this.settingData.minSales) < 1)) {
      const error = '天数和件数至少为1';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    try {
      this.isSubmit = true;
      await request({
        url: '/api/cloud/pms/refund/save/or/update',
        method: 'post',
        data: {
          ...this.settingData,
          ownerIds: this.purchaseSelectOwnerStore.mainSubStructureModel.gridModel.rows.map((item) => item.ownerId),
          lackOrderOwnerIds: this.orderSelectOwnerStore.mainSubStructureModel.gridModel.rows.map((item) => item.ownerId),
        },
      });
      message.success('操作成功');
      this.handleClose();
    } finally {
      this.isSubmit = false;
    }
  };
}
