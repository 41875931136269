import { Tabs } from 'antd';
import { ProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { ENUM_ACTIVE_KEY } from './types';
import { AutomaticRefundSettingModal } from './automaticRefundSettingModal/automaticRefundSettingModal';
import { Store } from './store';

export default observer(() => {
  const store = React.useMemo(() => new Store(), []);
  return (
    <>
      <ProgrammeComponent
        key={store.activeKey}
        store={store.programme}
        summaryStatistic={<Pane store={store}/>}
      />
      <AutomaticRefundSettingModal automaticRefundSettingModalStore={store.automaticRefundSettingModalStore}/>
    </>
  );
});

@observer
class Pane extends React.Component<{ store: Store; }> {
  render() {
    const {
      activeKey,
      handleActiveKeyChange,
    } = this.props.store;
    return (
      <Tabs
        activeKey={activeKey}
        onChange={handleActiveKeyChange}
      >
        {
          Object.values(ENUM_ACTIVE_KEY)
            .map((item) => (
              <Tabs.TabPane
                key={item.value}
                tab={item.label}
              />
            ))
        }
      </Tabs>
    );
  }
}
