import type { PaginationData } from 'egenie-utils';
import { MainSubStructureModel, Programme, request } from 'egenie-utils';
import { nanoid } from 'nanoid';
import React from 'react';
import { detailGrid } from './detailGrid';
import { getCommonFilterItems } from './ownerProgramme';
import type { MainItem } from './types';
import type { Store } from './store';

export function vendorProgramme(context: Store): Programme {
  return new Programme({
    gridModel: new MainSubStructureModel({
      buttons: [
        {
          permissionId: '3021',
          text: '退货出库',
          icon: 'icon-qrth',
          handleClick: () => context.handleClick(2, context.programme.filterItems.params.ownerIds as string),
        },
        {
          text: '导入',
          icon: 'icon-import',
          handleClick: () => context.handleImport(),
        },
        {
          permissionId: '6005',
          text: '自动退货设置',
          handleClick: () => context.handleAutomaticRefundSetting(),
        },
      ],
      pageId: '60026',
      grid: {
        columns: [
          {
            key: 'warehouseName',
            name: '仓库',
            width: 200,
          },
          {
            key: 'warehouseAreaName',
            name: '库区',
            width: 200,
          },
          {
            key: 'number',
            name: '库存数量',
            sortable: true,
            width: 80,
          },
          {
            key: 'vendorName',
            name: '供应商',
            sortable: true,
            sidx: 'vendorId',
            width: 200,
          },
          {
            key: 'cityName',
            name: '地区',
            width: 200,
          },
          {
            key: 'marketName',
            name: '市场',
            width: 200,
          },
        ].map((info) => ({
          resizable: true,
          sortable: false,
          ...info,
        })),
        rows: [],
        primaryKeyField: '_id',
        sortByLocal: false,
        showCheckBox: true,
        showEmpty: true,
        pageSize: 200,
        setColumnsDisplay: true,
        gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/returnGoods/index/vendorMain',
      },

      // 主表查询api
      api: {
        onQuery: (params) => {
          const {
            filterParams = {},
            ...rest
          } = params;
          return request<PaginationData<MainItem>>({
            url: '/api/cloud/wms/rest/refund/vendor/list/by/page',
            method: 'POST',
            data: {
              ...filterParams,
              ...rest,
            },
          })
            .then((info) => ({
              status: 'Successful',
              data: {
                ...info.data,
                list: (info.data?.list || []).map((item) => ({
                  ...item,
                  _id: nanoid(),
                })),
              },
            }));
        },
      },
      subTables: {
        activeTab: 'detail',
        tabsFlag: {
          inited: {
            detail: true,
            log: false,
          },
          searched: {},
        },
        list: [detailGrid(context)],
      },
    }),
    filterItems: getCommonFilterItems(context),
    moduleName: 'egenieCloudWmsPurchaseOrderReturnGoodsIndexVendor',
  });
}
