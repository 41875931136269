import { Modal, Button, Row, Checkbox, InputNumber } from 'antd';
import { MainSubStructure } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { SelectOwnerModal } from './selectOwnerModal/selectOwnerModal';
import type { AutomaticRefundSettingModalStore } from './automaticRefundSettingModalStore';
import { formatNumber, egeniePcTheme } from 'egenie-common';

export const AutomaticRefundSettingModal = observer(({
  automaticRefundSettingModalStore: {
    handleClose,
    open,
    isSubmit,
    handleSubmit,
    purchaseSelectOwnerStore,
    orderSelectOwnerStore,
    settingData,
    handleSettingEnableChange,
    handleSettingLackOrderEnableChange,
    handleSettingSaleDaysChange,
    handleSettingMinSalesChange,
  },
}: { automaticRefundSettingModalStore: AutomaticRefundSettingModalStore; }) => {
  return (
    <>
      <Modal
        centered
        confirmLoading={isSubmit}
        destroyOnClose
        maskClosable={false}
        onCancel={handleClose}
        onOk={handleSubmit}
        open={open}
        title="自动退货设置"
        width={1000}
      >
        <Row align="middle">
          <Checkbox
            checked={settingData.enable}
            onChange={handleSettingEnableChange}
          >
            当某个SKU超过
          </Checkbox>
          <InputNumber
            max={9999}
            min={0}
            onChange={handleSettingSaleDaysChange}
            placeholder="请输入天数"
            value={settingData.saleDays == null ? null : formatNumber(settingData.saleDays)}
          />
          &nbsp;&nbsp;天销量低于&nbsp;&nbsp;
          <InputNumber
            max={99999999}
            min={0}
            onChange={handleSettingMinSalesChange}
            placeholder="请输入件数"
            value={settingData.minSales == null ? null : formatNumber(settingData.minSales)}
          />
          &nbsp;&nbsp;件时,生成退货&nbsp;&nbsp;
          <Button
            onClick={purchaseSelectOwnerStore.handleSelectOwner}
            type="primary"
          >
            选择货主
          </Button>
        </Row>
        <div style={{ height: 250 }}>
          <MainSubStructure store={purchaseSelectOwnerStore.mainSubStructureModel}/>
        </div>
        <Row
          align="middle"
          style={{ marginTop: egeniePcTheme.spacing['egenie-spacing-xss'] }}
        >
          <Checkbox
            checked={settingData.lackOrderEnable}
            onChange={handleSettingLackOrderEnableChange}
          >
            当有缺货订单时,自动转移列表中的代发货主的自有库存发货
          </Checkbox>
          <Button
            onClick={orderSelectOwnerStore.handleSelectOwner}
            type="primary"
          >
            选择货主
          </Button>
        </Row>
        <div style={{ height: 250 }}>
          <MainSubStructure store={orderSelectOwnerStore.mainSubStructureModel}/>
        </div>
      </Modal>
      <SelectOwnerModal selectOwnerModalStore={purchaseSelectOwnerStore.purchaseSelectOwnerModalStore}/>
      <SelectOwnerModal selectOwnerModalStore={orderSelectOwnerStore.purchaseSelectOwnerModalStore}/>
    </>
  );
});

