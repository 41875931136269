import { message, Modal, Radio } from 'antd';
import type { BaseData } from 'egenie-common';
import { getStaticResourceUrl, destroyAllModal, renderModal } from 'egenie-common';
import type { Programme } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable, toJS } from 'mobx';
import React from 'react';
import { getOwner, getWarehouse, getWarehouseArea, ImportFile } from '../../../utils';
import { ownerProgramme } from './ownerProgramme';
import type { MainItem, SubItem } from './types';
import { ENUM_ACTIVE_KEY } from './types';
import { vendorProgramme } from './vendorProgramme';
import { AutomaticRefundSettingModalStore } from './automaticRefundSettingModal/automaticRefundSettingModalStore';

export class Store {
  constructor() {
    this.ownerProgramme = ownerProgramme(this);
    this.vendorProgramme = vendorProgramme(this);
    this.programme = this.ownerProgramme;

    getOwner()
      .then((data) => {
        this.ownerProgramme.filterItems.addDict({ ownerIds: data });
        this.vendorProgramme.filterItems.addDict({ ownerIds: data });
      });
    getWarehouse()
      .then((data) => {
        this.ownerProgramme.filterItems.addDict({ warehouseId: data });
        this.vendorProgramme.filterItems.addDict({ warehouseId: data });
      });
    getWarehouseArea()
      .then((data) => {
        this.ownerProgramme.filterItems.addDict({ warehouseAreaIds: data });
        this.vendorProgramme.filterItems.addDict({ warehouseAreaIds: data });
      });

    request<BaseData<Array<{ marketId?: string; marketName; }>>>({ url: '/api/cloud/baseinfo/rest/gim/market/list' })
      .then((info) => {
        const data = (info.data || []).map((item) => ({
          value: item.marketId,
          label: item.marketName,
        }));
        this.ownerProgramme.filterItems.addDict({ marketId: data });
        this.vendorProgramme.filterItems.addDict({ marketId: data });
      });
  }

  private isReturnGoods = false;

  public automaticRefundSettingModalStore: AutomaticRefundSettingModalStore = new AutomaticRefundSettingModalStore();

  @action public handleClick = (refundType: 1 | 2, ownerIdStr?: string) => {
    const mainSelectRows: MainItem[] = this.programme.gridModel.gridModel.selectRows;
    let selectSubRows: SubItem[] = [];
    if (this.isReturnGoods) {
      message.warning({
        key: '正在出库',
        content: '正在出库',
      });
      return;
    }

    if (this.programme.gridModel.subTablesModel.activeTab === 'detail') {
      const subModel = this.programme.gridModel.subTablesModel.listModel.find((item) => item.tab.value === 'detail');
      selectSubRows = subModel.gridModel.selectRows || [];
    }

    if (selectSubRows.length > 0 && mainSelectRows.length <= 1) {
      const item: MainItem = (mainSelectRows.length ? mainSelectRows[0] : this.programme.gridModel.gridModel.cursorRow || {}) as MainItem;
      Modal.confirm({
        content: '确认出库?',
        onOk: async() => {
          this.isReturnGoods = true;
          try {
            await request({
              url: '/api/cloud/wms/rest/refund/stock/order/purchase/refunds/goods/add/order',
              method: 'post',
              timeout: 1000 * 300,
              data: {
                ownerIdStr,
                needSplit: 0,
                refundType,
                orderVOS: [
                  {
                    purchaseRefundDetailCreateVOS: toJS(selectSubRows),
                    ...item,
                  },
                ],
              },
            });

            message.success({
              key: '出库成功',
              content: '出库成功',
            });
            this.programme.gridModel.onQuery();
          } finally {
            this.isReturnGoods = false;
          }
        },
      });
    } else if (selectSubRows.length === 0 && mainSelectRows.length > 0) {
      const vendorCount = new Set(mainSelectRows.map((item) => item.vendorShopId)).size;
      let needSplit = vendorCount > 1 ? 1 : 0;
      Modal.confirm({
        title: '确认出库?',
        content: vendorCount > 1 ? (
          <Radio.Group
            defaultValue={needSplit}
            onChange={(e) => needSplit = e.target.value}
            options={[
              {
                value: 1,
                label: '一个货主一个供应商生成一个出库单',
              },
              {
                value: 0,
                label: '汇总生成一个出库单',
              },
            ]}
          />
        ) : null,
        onOk: async() => {
          this.isReturnGoods = true;
          try {
            await request({
              url: '/api/cloud/wms/rest/refund/stock/order/purchase/refunds/goods/add/order',
              method: 'post',
              timeout: 1000 * 300,
              data: {
                ownerIdStr,
                needSplit,
                refundType,
                orderVOS: toJS(mainSelectRows),
              },
            });

            message.success({
              key: '出库成功',
              content: '出库成功',
            });
            this.programme.gridModel.onQuery();
          } finally {
            this.isReturnGoods = false;
          }
        },
      });
    } else {
      message.warning({
        key: '请选择子表或者主表数据',
        content: '请选择子表或者主表数据',
      });
    }
  };

  @action public handleImport = () => {
    renderModal(
      <ImportFile
        callback={((params) => {
          const fd = new FormData();
          for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
              fd.append(key, params[key]);
            }
          }

          return request({
            url: '/api/cloud/wms/rest/excelImport/purchaseRefunds/outOtherStockOrderDetailImport',
            method: 'post',
            data: fd,
          })
            .then(() => {
              this.programme.gridModel.onQuery();
              message.success('上传成功');
              destroyAllModal();
            });
        })}
        onCancel={destroyAllModal}
        templateUrl={getStaticResourceUrl('pc/ts/egenie-cloud-wms/downloadTemplate/采购退货导入模板.xlsx')}
      />
    );
  };

  @action public handleAutomaticRefundSetting = () => {
    this.automaticRefundSettingModalStore.handleOpen();
  };

  @observable public activeKey = ENUM_ACTIVE_KEY.owner.value;

  @action public handleActiveKeyChange = (activeKey: string) => {
    this.activeKey = activeKey;

    if (activeKey === ENUM_ACTIVE_KEY.owner.value) {
      this.programme = this.ownerProgramme;
    } else if (activeKey === ENUM_ACTIVE_KEY.vendor.value) {
      this.programme = this.vendorProgramme;
    }

    this.programme.gridModel.gridModel.resetAll();
    this.programme.gridModel.onRefresh();
  };

  @observable.ref public programme: Programme;

  public ownerProgramme: Programme;

  public vendorProgramme: Programme;
}
